import { HomeFeedShortcutLayout } from '../modules/home-feed-shortcut/components';
import HomeFeedShortcutTemplate from '../modules/home-feed-shortcut/template/HomeFeedShortcutTemplate';

export default function HomeFeedShortcutPage() {
  return (
    <HomeFeedShortcutLayout>
      <HomeFeedShortcutTemplate />
    </HomeFeedShortcutLayout>
  );
}
